import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress' // Progress 进度条
import 'nprogress/nprogress.css'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)
const routes = [
    {
        name: 'Login',
        path: '/',
        component: resolve => require(['@/components/Login/index'], resolve)
    },
    {
        name: 'Main',
        path: '/main',
        component: resolve => require(['@/components/Main/index'], resolve),
        children: [
            {
                name: 'Welcome',
                path: '/welcome',
                component: resolve => require(['@/components/Main/welcome/Welcome'], resolve)
            },
            {
                path: '/',
                redirect: '/welcome'
            },
            {
                path: '/organization',
                component: resolve => require(['@/components/Main/Organization'], resolve)
            },
            {
                path: '/journal',
                component: resolve => require(['@/components/Main/Journal'], resolve)
            },
            {
                path: '/resetPassword',
                component: resolve => require(['@/components/Modify/ModifyPassword'], resolve)
            },
            {
                path: '/resetName',
                component: resolve => require(['@/components/Modify/ModifyName'], resolve)
            },
            {
                path: '/searchStaff',
                component: resolve => require(['@/components/Search/SearchStaff'], resolve)
            },
            {
                path: '/searchArea',
                component: resolve => require(['@/components/Search/SearchArea'], resolve)
            }, {
                path: '/searchOrganization',
                component: resolve => require(['@/components/Search/SearchOrganization'], resolve)
            },
            {
                path: '/createStaff',
                component: resolve => require(['@/components/Common/CreateStaff'], resolve)
            },
            {
                path: '/authority',
                component: resolve => require(['@/components/Main/Authority/AuthorityManage'], resolve)
            },
            {
                path: '/role',
                component: resolve => require(['@/components/Main/Role/RoleManage'], resolve)
            },
            {
                path: '/modifyStaffAuthority/:staffId',
                name: 'ModifyStaffAuthority',
                component: resolve => require(['@/components/Search/components/ModifyAuthority'], resolve),
            },
            {
                path: '/message',
                component: resolve => require(['@/components/Messgae/Message'], resolve),
            },
            {
                path: '/notice',
                component: resolve => require(['@/components/Notice/Notice'], resolve),
            },
            {
                path: '/communication',
                component: resolve => require(['@/components/Communication/Communication'], resolve),
            },
            {
                path: '/noticeDetails/:noticeId',
                name: 'NoticeDetails',
                component: resolve => require(['@/components/Notice/components/NoticeDetails'], resolve),
            }
        ]
    }
]
const router = new VueRouter({
    routes,
    scrollBehavior(to, from, savedPosition) {
        return {
            x: 0,
            y: 0
        }
    }
})
router.beforeEach((to, from, next) => {
    NProgress.start()
    next() // 结束Progress
})
router.afterEach(() => {
    NProgress.done() // 结束Progress
})

export default router
