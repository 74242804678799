import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/css/global.css'
import axios from 'axios'
import qs from 'qs'
import * as echarts from 'echarts'
import VuePageTransition from 'vue-page-transition'
import VueCookies from 'vue-cookies'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import store from "@/store";
import COS from 'cos-js-sdk-v5'


// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import {
    Menu,
    MenuItem,
    MenuItemGroup,
    Message,
    Submenu,
    Timeline,
    TimelineItem,
    MessageBox,
    Select,
    Option,
    Tag,
    Divider,
    Badge,
    DatePicker,
    TimePicker,
    TimeSelect,
    Switch,
    Tabs,
    TabPane,
    Empty
} from 'element-ui'
import Chat from 'vue-beautiful-chat'


const cos = new COS({
    SecretId: 'AKIDjIdjZ9brtgEWaNvDG3bv0rSntqY0C9qH',
    SecretKey: 'C9rKYWGkywf8LJ1UheMp20iCPM4WieKk'
})
export default cos
Vue.use(Empty)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(MenuItemGroup)
Vue.use(Submenu)
Vue.use(Timeline)
Vue.use(TimelineItem)
Vue.use(Select)
Vue.use(Option)
Vue.use(Tag)
Vue.use(Divider)
Vue.use(Badge)
Vue.use(DatePicker)
Vue.use(TimeSelect)
Vue.use(TimePicker)
Vue.use(Switch)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.prototype.$confirm = MessageBox
Vue.prototype.$message = Message

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(VueCookies)
Vue.use(VuePageTransition)
Vue.use(IconsPlugin)
Vue.use(Chat)

Vue.prototype.$echarts = echarts

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
